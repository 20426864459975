<template>
    <div class="main-dask-table-page">
        <el-table  :data="tableData" style="width: 100%">
            <el-table-column label="№ п/п" type="index" sortable   :index="indexMethod" />
            <el-table-column prop="name" sortable label="Наименование КУ"/>
            <el-table-column
                prop="type"
                sortable
                width="160"
                label="Тип контроллера"
                :filters="filterListType"
                :filter-method="filterTagType"
            />
            <el-table-column prop="status" sortable label="Статус КУ"/>
            <el-table-column prop="type_connection" sortable label="Сеть" >
                <template #default="scope">
                    {{ scope.row.type_connection == 'WiFi'?'WiFi':(operatorsObject[scope.row.type_connection] == undefined?scope.row.type_connection:operatorsObject[scope.row.type_connection]) }}
                </template>
            </el-table-column>
            <el-table-column prop="signal_quality" label="Уровень сигнала" />
            <!-- <el-table-column prop="statusKU" label="Переход к параметрам КУ"/> -->
            <el-table-column 
                prop="dealer_name"
                sortable
                label="Дилер"
                :filters="filterListDealer"
                :filter-method="filterTagDealer"
            />
            <el-table-column prop="users_data" sortable label="Дата регистрации">
                <template #default="scope">
                    <p v-for="item, index in scope.row.users_data" :key="index">
                        {{ item.date }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column prop="users_data" sortable label="Email">
                <template #default="scope">
                    <p v-for="item, index in scope.row.users_data" :key="index"><a href="mailto:{{item.email}}">{{item.email}}</a></p>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'TablePage',
    props: [],
    computed: {
        ...mapGetters({
            tableData: 'getCompressorTableList',
            operatorList: 'getOperatorList'
            // compressorsList: 'getCompressorsList',
            // StatusDescriptions: 'getStatusDescriptions',
            // dealerList: 'getDealerList'
        }),
        operatorsObject() {
            let operators = {}
            this.operatorList.forEach(el=>{
                operators[el.code] = el.name
            })
            operators[null] = 'Неизвестно'
            return operators
        },
        filterListDealer() {
            let dealerList = []
            this.tableData.forEach(el=>{
                if (dealerList.indexOf(el.dealer_name) == -1) {
                    dealerList.push(el.dealer_name)
                }
            })
            dealerList = dealerList.map(el=>{
                return {
                    text: el,
                    value: el
                }
            })
            return dealerList
        },
        filterListType() {
            let typeList = [];
            this.tableData.forEach(el=>{
                if (typeList.indexOf(el.type) == -1) {
                    typeList.push(el.type)
                }
            })
            typeList = typeList.map(el=> {
                return {
                    text: el,
                    value: el
                }
            })
            return typeList
        }
    },
    watch: {
    },
    data() {
        return {
            timeOutID: null,
        }
    },
    components: {
    },
    methods: {
        ...mapActions({
            getCompressorsTable: 'getApiCompressorsTable',
            getOperators: 'getApiOperators'
        }),
        indexMethod(index) {
            return index+1
        },
        updateData() {
            this.getCompressorsTable();
            if (!this.flagStopTimeout) {
                this.timeOutID = setTimeout(this.updateData, 10000)
            }
        },
        handleEdit(index, row) {
            console.log(index, row)
        },
        filterTagDealer(value, row) {
            return row.dealer_name === value
        },
        filterTagType(value, row) {
            return row.type === value
        }
    },
    created() {
        this.flagStopTimeout = false;
        this.getCompressorsTable();
        this.timeOutID = setTimeout(this.updateData, 10000);
        this.getOperators()
    },
    beforeUnmount() {
    }
}
</script>

<style noscoped lang="scss">
.main-dask-table-page{
    
}

</style>